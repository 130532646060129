<template>
  <div id="container">
    <div id="left">
      <p>Explore</p>
      <b style="font-size: 40px">Our Solutions</b>
      <div id="hiddenSolutions">
        <button @click="show('content1')" style="border-bottom: solid gray 1px">
          Speech and Language
        </button>
        <button @click="show('content2')" style="border-bottom: solid gray 1px">
          Mental Health
        </button>
        <button @click="show('content3')" style="border-bottom: solid gray 1px">
          Motor Skills
        </button>
        <button @click="show('content4')" style="border-bottom: solid gray 1px">
          Reading and Writing
        </button>
        <button @click="show('content5')" style="border-bottom: solid gray 1px">
          Cognitive Skills
        </button>
        <button @click="show('content6')" style="border-bottom: solid gray 1px">
          Marketplace/App Admin
        </button>
        <button @click="show('content7')" style="border-bottom: solid gray 1px">
          NL1Dashboard
        </button>
        <button @click="show('content8')">Intervention Planning</button>
      </div>
      <table id="shownSolutions">
        <tr>
          <td @click="show('content1')" style="border-bottom: solid gray 1px">
            Speech and Language
          </td>
        </tr>
        <tr>
          <td @click="show('content2')" style="border-bottom: solid gray 1px">
            Mental Health
          </td>
        </tr>
        <tr>
          <td @click="show('content3')" style="border-bottom: solid gray 1px">
            Motor Skills
          </td>
        </tr>
        <tr>
          <td @click="show('content4')" style="border-bottom: solid gray 1px">
            Reading and Writing
          </td>
        </tr>
        <tr>
          <td @click="show('content5')" style="border-bottom: solid gray 1px">
            Cognitive Skills
          </td>
        </tr>
        <tr>
          <td @click="show('content6')" style="border-bottom: solid gray 1px">
            Marketplace/App Admin
          </td>
        </tr>
        <tr>
          <td @click="show('content7')" style="border-bottom: solid gray 1px">
            NL1Dashboard
          </td>
        </tr>
        <tr>
          <td @click="show('content8')">Intervention Planning</td>
        </tr>
      </table>
    </div>
    <div id="right">
      <div id="content1" class="contentk first">
        <img src="../img/NLC new website/oursolution1.png" alt="" />
        <br />
        <b style="font-size: 30px">Speech and Language</b>
        <p style="font-size: 25px">
          We carry screening, assessment, and intervention tools for speech and
          language.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content2" class="contentk">
        <img src="../img/NLC new website/oursolution2.png" alt="" />
        <br />
        <b style="font-size: 30px">Mental Health</b>
        <p style="font-size: 25px">
          We carry screening, assessment, and intervention tools for mental
          health.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content3" class="contentk">
        <img src="../img/NLC new website/oursolution3.png" alt="" />
        <br />
        <b style="font-size: 30px">Motor Skills</b>
        <p style="font-size: 25px">
          We carry screening, assessment, and intervention tools for motor
          skills needs. This includes online guided activities and grading as
          well as engaging sensory modules and videos.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content4" class="contentk">
        <img src="../img/NLC new website/oursolution4.png" alt="" />
        <br />
        <b style="font-size: 30px">Reading and Writing</b>
        <p style="font-size: 25px">
          We carry screening, assessment, and intervention tools for reading and
          writing.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content5" class="contentk">
        <img src="../img/NLC new website/oursolution5.png" alt="" />
        <br />
        <b style="font-size: 30px">Cognitive Skills</b>
        <p style="font-size: 25px">
          We carry screening, assessment, and intervention tools for cognitive
          skills needs, which tests skills such as logical reasoning, attention,
          perception, and memory.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content6" class="contentk">
        <img src="../img/NLC new website/oursolution6.png" alt="" />
        <br />
        <b style="font-size: 30px">Marketplace/App Admin</b>
        <p style="font-size: 25px">
          Create screening tests/quizzes/assessments with only a spreadsheet!
          Our AppAdmin platform lets you create custom apps to assess your
          student/patient’s skills (such as spelling or math) or evaluate
          disorder criteria. AppAdmin will automatically analyze your child’s
          performance, giving you in-depth data reports so you can make informed
          decisions about your student/patient’s development. <br />No time to
          create your own? No worry! Use our Marketplace, a hub with several
          premade screening tests/quizzes/assessments for disorders and skills
          to choose from.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content7" class="contentk">
        <img src="../img/NLC new website/oursolution7.png" alt="" />
        <b style="font-size: 30px">NL1Dashboard</b>
        <p style="font-size: 25px">
          Our dashboard allows you to leverage data to track the development of
          your student/patients across a variety of areas and categories. Using
          our NL1Dashboard, make insight-driven and data-informed decisions
          about your patient/student’s treatment plan.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
      <div id="content8" class="contentk">
        <img src="../img/NLC new website/oursolution8.png" alt="" />
        <br />
        <b style="font-size: 30px">Intervention Planning</b>
        <p style="font-size: 25px">
          Our intervention planning platforms allows you to plan, start, and
          track activities according to the development goals of your
          child/patient. Integrated with reporting papers, graded activities,
          and intelligent recommendations, make sure your child’s intervention
          plan is going the right track.
        </p>
        <button>
          <a href="https://www.vividkidsblog.page/products" style="color: white"
            >Learn More</a
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    show(contentId) {
      const contents = Array.from(document.getElementsByClassName("contentk"));
      //   alert(contents);
      contents.forEach((content) => (content.style.display = "none"));
      document.getElementById(contentId).style.display = "block";
    },
  },
};
</script>

<style scoped>
#container {
  display: flex;
}
#left {
  width: 45vw;
  padding-right: 10vw;
}
#right {
  width: 45vw;
}

table {
  border-collapse: collapse;
}

td {
  font-size: 30px;
  border: none;
}

/* #container #left table tr td:nth-of-type(even) {
  border-left: none;
} */
.contentk {
  display: none;
}
.contentk img {
  width: 35vw;
  height: auto;
}
.first {
  display: block;
}
button {
  padding: 10px 2px;
  border: none;
  border-radius: 5px;
  background-color: #f7901e;
  color: white;
}
#hiddenSolutions button {
  margin: 5px;
  padding: 1px;
}
#hiddenSolutions {
  display: none;
}
#shownSolutions {
  display: block;
}
@media screen and (max-width: 860px) {
  #container {
    display: block;
  }
  #left {
    width: 90vw;
  }
  #right {
    width: 90vw;
  }
  #right img {
    width: 90vw;
  }
  #hiddenSolutions {
    display: block;
  }
  #shownSolutions {
    display: none;
  }
}
</style>
