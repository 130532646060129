<template>
  <div>
    <img
      src="../img/NLC new website/vector.png"
      alt="vector-img"
      id="vector"
      style="
        width: 7%;
        height: fit-content;
        position: absolute;
        left: 0;
        top: 10%;
      "
    />
    <p style="color: #dc6803; padding-top: 5%">About us</p>
    <b style="font-size: 40px">Innovators in AI-Enhanced care</b>
    <br /><br /><br />
    <b style="font-size: 30px">Who we are</b>
    <br />
    <p style="font-size: 25px">
      Since 2015, NeuroLeap has been dedicated to transforming neurological and
      educational care through our unique combination of AI-enhanced hardware
      and software. We specialize in creating real-time, data-driven solutions
      for patients and students, for all ages, by providing personalized
      interventions/specialized lessons that adapt dynamically to individual
      needs.
    </p>
    <table>
      <tr>
        <td>
          <img src="../img/NLC new website/about1.png" alt="" id="firstimg" />
        </td>
        <td>
          <img src="../img/NLC new website/about2.png" alt="" id="secondimg" />
        </td>
      </tr>
    </table>
    <br /><br />

    <b style="font-size: 30px">Our Core Solutions</b>
    <table id="care">
      <tr>
        <td>
          <img
            src="../img/NLC new website/about3.png"
            alt=""
            style="width: 15vw"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/about4.png"
            alt=""
            style="width: 20vw"
          />
        </td>
      </tr>
      <tr>
        <td>
          <b style="color: #831b19; font-size: 25px">Neurological Care</b>
        </td>
        <td>
          <b style="color: #831b19; font-size: 25px">Educational Care:</b>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            Tailored solutions for conditions such as ADHD, TBI, CP, and other
            neurological disorders, delivering real-time insights for better
            outcomes, for 2 month old infants to adults.
          </p>
        </td>
        <td>
          <p>
            Norm-referenced/criterion-referenced dynamic assessments and
            personalized interventions for reading, speech, language, cognitive
            skills, motor skills and emotional & interpersonal health, helping
            students reach their fullest potential.
          </p>
        </td>
      </tr>
    </table>
    <br /><br />
    <div id="about_bg">
      <h2 style="color: #831b19">Mission</h2>
      <br />
      <p>
        At NeuroLeap, we are committed to redefining care and learning solutions
        through cutting-edge technologies. By harnessing the power of artificial
        intelligence, biosensors, robotics, and IoT devices, we strive to enable
        the earliest detection and intervention for learning disabilities,
        cognitive challenges, mental health issues, and motor skills
        impairments. Our mission is to transform lives and create a future where
        every individual can achieve their fullest potential.
      </p>
    </div>
    <br /><br />
    <ContactUs id="contactformAbout" />
  </div>
</template>
<script>
import ContactUs from "@/components/contactUs.vue";
export default {
  components: {
    ContactUs,
  },
};
</script>
<style scoped>
td {
  border: none;
  vertical-align: top;
}
#care td {
  width: 50%;
}
#about_bg {
  background-image: url("../img/NLC new website/about_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5%;
}
#firstimg {
  width: 38vw;
}
#secondimg {
  width: 38vw;
}
@media screen and (max-width: 860px) {
  #contactformAbout {
    margin: 0;
    padding: 0;
    position: relative;
    left: -12vw;
    width: 98vw;
  }
}
</style>
