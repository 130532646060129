<template>
  <div>
    <div>
      <img
        src="../img/NLC new website/vector.png"
        alt="vector-img"
        id="vector"
        style="
          width: 7%;
          height: fit-content;
          position: absolute;
          left: 0;
          top: 10%;
        "
      />
      <p style="color: #dc6803; padding-top: 5%">Contact</p>
      <b style="font-size: 40px"
        >Partner with Neuroleap for AI driven Solutions</b
      >
      <br /><br /><br />
      <b style="font-size: 30px"
        >Transform Neurological and Educational Care with NeuroLeap</b
      >
      <br />
      <p style="font-size: 25px">
        Our end-to-end AI solutions provide healthcare providers, schools, and
        rehabilitation centers with the tools they need to deliver precision
        care and personalized interventions/specialized lessons. Whether you’re
        a healthcare provider, school, or clinic, NeuroLeap’s integrated
        hardware and software platform can significantly help improve outcomes
        for both patients and students.
      </p>
      <br />
      <b style="font-size: 30px">What we offer</b>
      <ul style="font-size: 25px">
        <li>
          Integrated AI hardware and software solutions for real-time
          neurological and educational care.
        </li>
        <li>Customizable platforms for hospitals, clinics, and schools.</li>
        <li>
          Third-party marketplace for your norm-referenced/criterion-referenced
          screening, assessment and intervention method(s)/tool(s) to shine
          brighter and generate more revenue for you.
        </li>
      </ul>

      <br /><br /><br />
      <div id="contact_bg">
        <table>
          <tr>
            <td>
              <div style="width: 100%">
                <b style="color: #831b19; font-size: 40px">Connect with Us</b>
                <img src="../img/girl_open_door.png" alt="" id="IMG" />

                <br /><br />
                <p id="contact_bg_content">
                  Contact us today to schedule a demo and see how NeuroLeap can
                  revolutionize care at your organization. We’d love to take any
                  inquiries related to our product, collaboration, or working
                  with us.
                </p>
                <br /><br />
              </div>
            </td>
          </tr>
        </table>
      </div>
      <br /><br />
    </div>
    <ContactUs id="contactformContact" />
  </div>
</template>
<script>
import ContactUs from "@/components/contactUs.vue";
export default {
  components: {
    ContactUs,
  },
};
</script>
<style scoped>
#contact_bg {
  background-image: url("../img/NLC new website/contact_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5%;
}

td {
  border: none;
  vertical-align: top;
  width: 50%;
}
#IMG {
  float: right;
  width: 40vw;
  margin-left: 10%;
  border-radius: 7%;
}
#contact_bg_content {
  font-size: 25px;
  width: 50%;
}
@media screen and (max-width: 860px) {
  #contactformContact {
    margin: 0;
    padding: 0;
    position: relative;
    left: -12vw;
    width: 98vw;
  }
  #IMG {
    float: left;
    width: 55vw;
  }
  #contact_bg_content {
    width: 63vw;
  }
}
</style>
