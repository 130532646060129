<template>
  <div id="whyneuroLeap" class="whyNeuroLeap">
    <img
      src="../img/NLC new website/vector.png"
      alt="vector-img"
      id="vector"
      style="
        width: 7%;
        height: fit-content;
        position: absolute;
        left: 0;
        top: 10%;
      "
    />
    <br /><br />
    <b style="font-size: 40px">{{ $t("whyNeuroLeap.heading") }}</b>
    <br /><br />
    <p style="font-size: 25px">
      At NeuroLeap, we offer a truly end-to-end AI-powered solution that
      combines cutting-edge hardware and software to revolutionize both
      neurological care and educational support. Our platform is uniquely
      positioned to meet the complex needs of healthcare providers and
      school-based specialists by delivering dynamic assessments, real-time,
      adaptive, and personalized interventions/specialized lessons and training
      that evolve with each patient and student.
    </p>
    <br />

    <table id="hiddenWhyNeuro">
      <tr>
        <td>
          <img
            src="../img/NLC new website/why1.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">Comprehensive AI Solutions</b>
          <br />
          NeuroLeap stands out as one of the few companies offering an
          integrated platform that combines Edge-AI hardware (motion sensors,
          biosensors, computer vision) with Cloud-AI software (advanced
          algorithms for data analysis). This seamless integration provides
          real-time insights for both neurological treatment and educational
          development.
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why2.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">Precision and Personalization</b>
          <br />
          Our platform is designed to adapt to the individual needs of each
          user. Whether you're treating a patient with ADHD, traumatic brain
          injury (TBI), cerebral palsy (CP), or supporting a student with
          speech, literacy, fine-motor skills, or cognitive challenges,
          NeuroLeap delivers tailored interventions that adjust dynamically
          based on real-time data.
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why3.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px"
            >Dual Focus on Neurological and Educational Care</b
          >
          <br />
          Unlike other AI companies that focus on a single sector, NeuroLeap
          provides solutions across both healthcare and education. Our platform
          is built to serve hospitals, clinics, rehabilitation centers, and
          schools, ensuring flexibility and long-term value.
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why4.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">Real-Time, Adaptive Technology</b>
          <br />
          NeuroLeap’s AI-powered platform continuously monitors progress and
          adjusts treatment plans or learning paths in real time. This dynamic
          approach helps optimize outcomes for patients and students by
          constantly refining interventions based on actual performance.
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why5.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">Cutting-Edge AI and Cloud Integration</b>
          <br />
          By integrating edge AI processing with cloud-based scalability,
          NeuroLeap ensures fast, secure, and FERPA/HIPAA-compliant data
          handling. Our technology provides healthcare providers and
          school-based specialists with immediate, actionable insights while
          also supporting the long-term storage and analysis of complex
          datasets.
        </td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why6.png"
            alt=""
            style="width: 50px"
          />
          <br />
          <b style="font-size: 25px">Commitment to Innovation</b>
          <br />
          NeuroLeap is at the forefront of AI innovation in healthcare and
          education. Since 2015, we continuously push the boundaries of what's
          possible with AI, staying ahead of industry trends and incorporating
          the latest advances in technology to improve care and learning
          outcomes.
        </td>
      </tr>
    </table>

    <table id="shownWhyNeuro">
      <tr>
        <td>
          <img
            src="../img/NLC new website/why1.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why2.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why3.png"
            alt=""
            style="width: 50px"
          />
        </td>
      </tr>
      <tr>
        <td><b style="font-size: 30px">Comprehensive AI Solutions</b></td>
        <td><b style="font-size: 30px">Precision and Personalization</b></td>
        <td>
          <b style="font-size: 30px"
            >Dual Focus on Neurological and Educational Care</b
          >
        </td>
      </tr>
      <tr>
        <td>
          NeuroLeap stands out as one of the few companies offering an
          integrated platform that combines Edge-AI hardware (motion sensors,
          biosensors, computer vision) with Cloud-AI software (advanced
          algorithms for data analysis). This seamless integration provides
          real-time insights for both neurological treatment and educational
          development.
        </td>
        <td>
          Our platform is designed to adapt to the individual needs of each
          user. Whether you're treating a patient with ADHD, traumatic brain
          injury (TBI), cerebral palsy (CP), or supporting a student with
          speech, literacy, fine-motor skills, or cognitive challenges,
          NeuroLeap delivers tailored interventions that adjust dynamically
          based on real-time data.
        </td>
        <td>
          Unlike other AI companies that focus on a single sector, NeuroLeap
          provides solutions across both healthcare and education. Our platform
          is built to serve hospitals, clinics, rehabilitation centers, and
          schools, ensuring flexibility and long-term value.
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>
          <img
            src="../img/NLC new website/why4.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why5.png"
            alt=""
            style="width: 50px"
          />
        </td>
        <td>
          <img
            src="../img/NLC new website/why6.png"
            alt=""
            style="width: 50px"
          />
        </td>
      </tr>
      <tr>
        <td><b class="table_title">Real-Time, Adaptive Technology</b></td>
        <td>
          <b class="table_title">Cutting-Edge AI and Cloud Integration</b>
        </td>
        <td><b class="table_title">Commitment to Innovation</b></td>
      </tr>
      <tr>
        <td>
          NeuroLeap’s AI-powered platform continuously monitors progress and
          adjusts treatment plans or learning paths in real time. This dynamic
          approach helps optimize outcomes for patients and students by
          constantly refining interventions based on actual performance.
        </td>
        <td>
          By integrating edge AI processing with cloud-based scalability,
          NeuroLeap ensures fast, secure, and FERPA/HIPAA-compliant data
          handling. Our technology provides healthcare providers and
          school-based specialists with immediate, actionable insights while
          also supporting the long-term storage and analysis of complex
          datasets.
        </td>
        <td>
          NeuroLeap is at the forefront of AI innovation in healthcare and
          education. Since 2015, we continuously push the boundaries of what's
          possible with AI, staying ahead of industry trends and incorporating
          the latest advances in technology to improve care and learning
          outcomes.
        </td>
      </tr>
    </table>
    <br /><br /><br /><br />
  </div>
</template>

<script>
export default {
  // global function to make collapsible effect
  // gives every class called "collapsible" the actual collapsible effect for the next element
  mounted: function () {
    // For Donation
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://www.paypal.com/sdk/js?client-id=Acm_FNoZJuwPQTLbA-0NqylATBlhwanM71KkAGjAb9ryO_yrzsNv5NZgeg2XdHE1Y9IRGasT2KpmLhZm&enable-funding=venmo&currency=USD"
    );
    document.head.appendChild(externalScript);
  },
};
</script>

<style scoped>
#whyneuroLeap:target {
  padding-top: 7vh;
}

td {
  border: none;
  vertical-align: top;
  width: 33.33vw;
}
.table_title {
  font-size: 30px;
}
#shownWhyNeuro {
  display: block;
}
#hiddenWhyNeuro {
  display: none;
  width: 100%;
}
#hiddenWhyNeuro tr td {
  width: 100vw;
}
@media screen and (max-width: 860px) {
  #whyneuroLeap:target {
    padding-top: 15vh;
  }
  #whyneuroLeap {
    overflow-x: auto;
  }
  #shownWhyNeuro {
    display: none;
  }
  #hiddenWhyNeuro {
    display: block;
  }
}
</style>
