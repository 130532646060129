<template>
  <div id="faq" class="faq">
    <div id="title">
      <h1>Frequently asked questions</h1>
    </div>
    <br />

    <button class="collapsible">
      {{ $t("faq.collapse_1_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("faq.collapse_1_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("faq.collapse_2_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("faq.collapse_2_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("faq.collapse_3_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("faq.collapse_3_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("faq.collapse_4_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("faq.collapse_4_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("faq.collapse_5_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("faq.collapse_5_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("faq.collapse_6_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("faq.collapse_6_content") }}</p>
    </div>
    <br /><br />
    <!-- <h1 id="partner_small">
      Learn about our
      <router-link @click="routeToHome()" :to="{ path: '/partner' }">{{
        $t("Header[10]")
      }}</router-link>
    </h1> -->
  </div>
</template>

<script>
export default {
  methods: {
    routeToHome: function () {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
#title {
  display: flex;
  justify-content: center;
}
#faq {
  background-color: #f9f9f9;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 150px;
  padding-right: 150px;
}
.container .collapsible_content:not(:first-child) {
  border-top: 2px solid #000;
}
.collapsible {
  border: none;
}

@media screen and (min-width: 1380px) and (max-width: 1680px) {
  .faq {
    top: -270px;
  }
}
@media screen and (max-width: 1380px) {
  .faq {
    top: -200px;
  }
}
@media screen and (max-width: 700px) {
  .faq {
    top: -100px;
  }
}
/* #faq:target {
  position: relative;
  padding-top: 15vh;
} */
@media screen and (max-width: 860px) {
  #faq {
    padding: 0;
  }
}

@media screen and (min-width: 991px) {
  #partner_small {
    display: none;
  }
}
</style>
